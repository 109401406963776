import { ScreenContent, ScreenHeader } from '@cae/cobalt-react';

import {
  DashboardCarousel,
  DashboardGreeting,
  DashboardTabs,
} from '@/features/dashboard';
import FirstTimeUser from '@/features/first-time-user/FirstTimeUser';

import './HomePage.css';
import RoleBasedDashboardAlert from '@/features/dashboard/components/role-based-dashboard-alert/RoleBasedDashboardAlert';
import RoleBasedTableWrapper from '@/features/dashboard/components/role-base-wrapper/RoleBasedWrapper';
import { useUserInfo } from '@/contexts/userInfo';
import LoaderWithLabel from '@/shared/components/loader/Loader';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import AlertDashboardCarousel from '@/features/dashboard/components/alert-carousel/AlertDashboardCarousel';
import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { checkRoleMatch } from '@/shared/components/check-internal-roles/CheckInternalRoles';

function HomePage(): JSX.Element {
  const [isSpinnerDisplayed] = useState(false);
  const { isInternalUser, selectedOrganization, selectedOrganizationId } =
    useUserInfo();
  const navigate = useNavigate();
  const { customerorganizationswitcher, HomepageNewAlertCardsWebApp } =
    useFlags();
  const sfContact = sessionStorage.getItem('sfmastercontactid');
  const currentRoles = useCurrentRoles();
  const { instructormyschedule } = useFlags();

  if (
    selectedOrganizationId === null &&
    !customerorganizationswitcher &&
    !isInternalUser
  ) {
    return <Navigate to="/org-selector" />;
  }
  if (sfContact && sfContact.length > 0) {
    navigate('/sf-connect/home');
  }

  if (selectedOrganization === undefined) {
    return <LoaderWithLabel title="Loading organization" />;
  }

  if (
    currentRoles &&
    currentRoles.length === 1 &&
    checkRoleMatch(currentRoles, 'CAE Instructor') &&
    isInternalUser
  ) {
    if (instructormyschedule) {
      navigate('/schedule');
    }
  }

  return (
    <section className="page page-dashboard">
      {!isSpinnerDisplayed && (
        <div className="page-wrapper">
          <ScreenHeader className="screen-header--dashboard" elevation="base">
            <DashboardGreeting />
          </ScreenHeader>
          <div className="card-list" data-testid="reservationsAlertCards">
            {HomepageNewAlertCardsWebApp ? (
              <RoleBasedDashboardAlert />
            ) : (
              <AlertDashboardCarousel
                organizationId={selectedOrganizationId ?? ''}
              />
            )}
          </div>

          <ScreenContent>
            <div className="primary-content">
              <RoleBasedTableWrapper />
            </div>
            <aside>
              <DashboardTabs />
              <div className="card-carousel">
                <DashboardCarousel />
              </div>
            </aside>
          </ScreenContent>
        </div>
      )}
      <Outlet />
      {!isInternalUser && <FirstTimeUser />}
    </section>
  );
}

export default HomePage;
