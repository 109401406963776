import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { WidgetService, AllAlerts } from '@/open-api';

export const useGetAlertsDataQuery = (
  organizationId: string
): UseQueryResult<AllAlerts, Error> => {
  return useQuery<AllAlerts, Error>(
    ['getAllAlertsDetails', organizationId],
    () => WidgetService.getAllAlertsDetails({ organizationId }),
    {
      enabled: !!organizationId,
    }
  );
};
