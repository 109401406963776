/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaeCustomerAuthority } from '../models/CaeCustomerAuthority';
import type { CaeCustomerOperatingRegion } from '../models/CaeCustomerOperatingRegion';
import type { ConfigurationRequest } from '../models/ConfigurationRequest';
import type { ConfigurationResponse } from '../models/ConfigurationResponse';
import type { VehicleTypeDto } from '../models/VehicleTypeDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ConfigurationManagementService {
  /**
   * @returns ConfigurationResponse OK
   * @throws ApiError
   */
  public static getConfigurationById({
                                       id,
                                     }: {
    id: string;
  }): CancelablePromise<ConfigurationResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/configuration/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateConfiguration({
                                      id,
                                      requestBody,
                                    }: {
    id: string;
    requestBody: ConfigurationRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/configuration/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteConfiguration({
                                      id,
                                    }: {
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/configuration/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ConfigurationResponse OK
   * @throws ApiError
   */
  public static getAllConfigurations(): CancelablePromise<
    Array<ConfigurationResponse>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/configuration',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any Created
   * @throws ApiError
   */
  public static createConfiguration({
                                      requestBody,
                                    }: {
    requestBody: ConfigurationRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/configuration',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns CaeCustomerOperatingRegion OK
   * @throws ApiError
   */
  public static getOperatingRegions(): CancelablePromise<
    Array<CaeCustomerOperatingRegion>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/configuration/operatingRegions',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ConfigurationResponse OK
   * @throws ApiError
   */
  public static getConfigurationByName({
                                         name,
                                       }: {
    name: string;
  }): CancelablePromise<ConfigurationResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/configuration/name/{name}',
      path: {
        name: name,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns CaeCustomerAuthority OK
   * @throws ApiError
   */
  public static getAuthorities1(): CancelablePromise<
    Array<CaeCustomerAuthority>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/configuration/authorities',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns VehicleTypeDto OK
   * @throws ApiError
   */
  public static getAllVehicleTypes(): CancelablePromise<Array<VehicleTypeDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/configuration/allVehicleTypes',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
