import { useState } from 'react';
import { Modal, ScreenContent, ScreenHeader } from '@cae/cobalt-react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InstructorContainer } from '@/features/instructor-management';
import './InstructorsPage.css';
import HasAccess from '@/shared/components/has-access/HasAccess';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  InstructorsListing,
  InstructorsReportCards,
} from '@/features/instructors';
import { InstructorAlertCards } from '@/features/instructor-management/components/instructor-alerts/InstructorAlertCards.tsx';
import { DownloadReportAction } from '@/features/instructor-management/components/download-reports-action/DownloadReportAction.tsx';
import { useViewportSize } from '@/shared/hooks/useViewportSize.ts';
import { ReportsForm } from '@/features/instructor-management/components/reports-form/ReportsForm.tsx';
import { useUserInfo } from '@/contexts/userInfo.tsx';
import BatchInstructorsModal from '@/features/instructor-management/components/batch-instructors-modal/BatchInstructorsModal.tsx';
import { InstructorProgramAlertDto } from '@/open-api';

const comingSoonInstructorsPageContent = (
  <div className="page-instructors_coming-soon">
    <img
      src="/img/instructors/instructors-coming-soon.svg"
      alt="Instructors page coming soon!"
    />
    <h4>Coming soon!</h4>
    <p>
      We&apos;re currently bringing a new improved live instructor reporting
      section that will be displayed here. Come back soon to check it out!
    </p>
  </div>
);

export function InstructorsPage(): JSX.Element {
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const [reportOpen, setReportOpen] = useState(false);
  const [batchInstructorsModalOpen, setBatchInstructorsModalOpen] =
    useState(false);
  const [batchInstructorsData, setBatchInstructorsData] =
    useState<InstructorProgramAlertDto>();
  const { isInternalUser, isPart135Certified } = useUserInfo();
  const { comingsooninstructorspage, canViewOldInstructorManagementPage } =
    useFlags();
  const toggleReportsModal = (): void => setReportOpen(!reportOpen);

  if (!isInternalUser && !isPart135Certified) {
    navigate('/home');
    return <></>;
  }

  if (canViewOldInstructorManagementPage) {
    return (
      <HasAccess permissions="view_instructor_list">
        <section className="page page-instructors">
          <div className="page-wrapper">
            <ScreenHeader elevation="base">
              <hgroup>
                <h2>Instructor management</h2>
              </hgroup>
              <InstructorsReportCards />
            </ScreenHeader>
            <ScreenContent>
              {comingsooninstructorspage ? (
                comingSoonInstructorsPageContent
              ) : (
                <InstructorsListing />
              )}
            </ScreenContent>
            <Outlet />
          </div>
        </section>
      </HasAccess>
    );
  } else {
    return (
      <HasAccess permissions="view_instructor_list">
        <section className="page page-instructor-management">
          <div className="page-wrapper">
            <ScreenHeader elevation="base">
              <hgroup>
                <h2>Instructor management</h2>
                <DownloadReportAction
                  permitted={true}
                  label={'Download reports'}
                  onDownloadReportClick={toggleReportsModal}
                />
              </hgroup>
              <InstructorAlertCards
                setBatchInstructorsModalOpen={setBatchInstructorsModalOpen}
                setBatchInstructorsData={setBatchInstructorsData}
              />
            </ScreenHeader>
            <ScreenContent>
              {comingsooninstructorspage ? (
                comingSoonInstructorsPageContent
              ) : (
                <InstructorContainer />
              )}
            </ScreenContent>
            <Modal
              open={reportOpen}
              onCancel={toggleReportsModal}
              placement={width < 480 ? 'bottom' : 'right'}
              dismissible
              size="sm"
              className="instructor-management--dialog"
            >
              {reportOpen && <ReportsForm onCancel={toggleReportsModal} />}
            </Modal>
            <BatchInstructorsModal
              modalOpen={batchInstructorsModalOpen}
              toggleModal={() =>
                setBatchInstructorsModalOpen(!batchInstructorsModalOpen)
              }
              trainingCenterId={batchInstructorsData?.payload?.location ?? ''}
              program={batchInstructorsData?.payload?.program ?? ''}
              discipline={batchInstructorsData?.payload?.discipline}
            />
          </div>
        </section>
        <Outlet />
      </HasAccess>
    );
  }
}
