import { useState, useEffect } from 'react';
import { Modal, Button } from '@cae/cobalt-react';
import { DisciplineTypeDto } from '@/open-api';
import './RegulationModal.css';

interface RegulationModalProps {
  modalOpen: boolean;
  toggleModal: () => void;
  discipline: DisciplineTypeDto | undefined;
}

const RegulationModal = ({
  modalOpen,
  toggleModal,
  discipline,
}: Readonly<RegulationModalProps>): JSX.Element => {
  const [content, setContent] = useState<{
    header: string;
    subheader: string;
    body: string | JSX.Element;
    nextPara: string;
    lastPara: string;
  }>({
    header: '',
    subheader: '',
    body: '',
    nextPara: '',
    lastPara: '',
  });

  useEffect(() => {
    if (discipline === DisciplineTypeDto.CHECK_AIRMAN) {
      setContent({
        header: 'Regulations for instructors',
        subheader: 'Check airmen',
        body: (
          <>
            The following CAE Training Center Evaluators have completed a CAE
            TCE curriculum designed to meet the following:
            <ol type={'a'}>
              <li>
                The training and checking qualifications outlined in 14 CFR
                Parts 135.337 and 135.339.
              </li>
              <li>
                Within the preceding 24 calendar months, have conducted a
                simulator proficiency check under the observation of an FAA
                inspector.
              </li>
              <li>
                Differences training between the Certificate Holder&apos
                training program and the CAE Part 142 core curriculum is
                accomplished by the use of the following processes: The
                application of the Operator Specific briefing sheet, the Part
                135 Operator Specific Read File, the use of Operator Specific
                Standard Operating Procedures, Flight Maneuvers and Profiles,
                Normal, Abnormal and Emergency Checklists, and the documentation
                of the flight training record certifying the knowledge of and
                application of these Operator Specific training documents.
              </li>
            </ol>
          </>
        ),
        nextPara:
          'The following CAE SimuFlite Training Center Evaluators are eligible to be nominated by the certificate holder as contract simulator check airmen.',
        lastPara:
          'Instructors appearing that fail to maintain currency on the assigned aircraft will not be utilized for any training event.',
      });
    } else {
      setContent({
        header: 'Regulations for instructors',
        subheader: 'Ground school and simulator',
        body: (
          <>
            The following CAE Training Center Instructors will have completed a
            CAE Instructor Curriculum designed to meet the following:
            <ol type={'a'}>
              <li>
                The training and checking requirements outlined in 14 CFR Parts
                135.338 and 135.340.
              </li>
              <li>
                Within the preceding 24 calendar months, have conducted
                simulator instruction under the observation of an FAA inspector
                or a Check Airman.
              </li>
              <li>
                Differences training between the Certificate Holder&apos
                training program and the CAE Part 142 core curriculum is
                accomplished by the use of the following process: The
                application of the Operator Specific briefing sheet, the Part
                135 Operator Specific Read File, the use of Operator Specific
                Standard Operating Procedures Flight Maneuvers and Profiles,
                Normal, Abnormal and Emergency Checklists, and the documentation
                of the flight training record certifying the knowledge of and
                application of these Operator Specific training documents.
              </li>
            </ol>
          </>
        ),
        nextPara:
          'The following CAE Training Center Instructors are eligible to be approved by the certificate holder as contract air transportation simulator instructors and ground instructors.',
        lastPara:
          'Instructors appearing that fail to maintain currency on the assigned aircraft will not be utilized for any training event.',
      });
    }
  }, [discipline]);

  return (
    <Modal
      open={modalOpen}
      onCancel={toggleModal}
      size="sm"
      className="regulation-modal"
    >
      <div className="modal-header">
        <h2>{content.header}</h2>
      </div>
      <div className="modal-subheader">
        <h3>{content.subheader}</h3>
      </div>
      <div className="modal-body">
        <div>{content.body}</div>
        <p>{content.nextPara}</p>
        <p className="semi-bold">{content.lastPara}</p>
      </div>
      <div className="modal-footer">
        <Button onClick={toggleModal}>Done</Button>
      </div>
    </Modal>
  );
};

export default RegulationModal;
