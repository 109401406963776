import { useState } from 'react';
import {
  Modal,
  Card,
  Button,
  Avatar,
  AlertBanner,
  ProgressLine,
} from '@cae/cobalt-react';
import './BatchInstructorsModal.css';
import { DisciplineTypeDto, InstructorApprovalRequestType } from '@/open-api';
import { useAvailableInstructorsQuery } from '@/features/instructor-management/api/useAvailableInstructorsQuery.ts';
import {
  disciplineTextMap,
  getNameInitials,
} from '@/features/instructor-management/shared/Utils.tsx';
import { useUserInfo } from '@/contexts/userInfo.tsx';
import { useInstructorApproveQuery } from '@/features/instructor-management/api/useInstructorApproveQuery.ts';
import { DataLoadingError } from '@/shared/components';
import { NoData } from '@/features/record-details/components/empty-states/NoData.tsx';
import RegulationModal from '@/features/instructor-management/components/regulation-modal/RegulationModal.tsx';

interface BatchInstructorsModalProps {
  modalOpen: boolean;
  toggleModal: () => void;
  trainingCenterId: string;
  program: string;
  discipline: DisciplineTypeDto | undefined;
}

const BatchInstructorsModal = ({
  modalOpen,
  toggleModal,
  trainingCenterId,
  program,
  discipline,
}: Readonly<BatchInstructorsModalProps>): JSX.Element => {
  const { data, isLoading, isError, isSuccess } = useAvailableInstructorsQuery(
    program,
    trainingCenterId,
    discipline
  );
  const { approveInstructor } = useInstructorApproveQuery();
  const {
    availableInstructors: instructors = [],
    trainingCenterName: location = '',
  } = data || {};
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const { selectedOrganizationId } = useUserInfo();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isRequestSuccessfully, setIsRequestSuccessfully] = useState(false);
  const [regulationModalOpen, setRegulationModalOpen] = useState(false);

  const handleCheckbox = (id: string): void => {
    if (!id) return;
    setError('');
    setSelected({
      ...selected,
      [id]: !selected[id],
    });
  };

  const handleSelectAll = (): void => {
    setError('');
    const allSelected = instructors.reduce(
      (acc, instructor) => {
        acc[instructor?.instructorId ?? ''] = true;
        return acc;
      },
      {} as Record<string, boolean>
    );
    setSelected(allSelected);
  };

  const handleSubmit = (): void => {
    setLoading(true);
    const payload = Object.keys(selected)
      .filter(key => key)
      .map(key => ({
        organizationId: selectedOrganizationId ?? undefined,
        requestType: InstructorApprovalRequestType.APPROVE,
        instructorId: key,
        discipline,
        location,
        program,
      }));
    approveInstructor(payload)
      .then(() => {
        setLoading(false);
        setSelected({});
        setIsRequestSuccessfully(true);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  const handleClose = (): void => {
    setSelected({});
    setError('');
    setLoading(false);
    setIsRequestSuccessfully(false);
    toggleModal();
  };

  const getSelectedCount = (): number => {
    return Object.values(selected).filter(value => value).length;
  };

  const getModalContent = (): JSX.Element => {
    if (isLoading) {
      return (
        <div className="info-section">
          <ProgressLine className="" label="Loading…" />
        </div>
      );
    }

    if (isError) {
      return (
        <DataLoadingError
          heading={
            'An error occurred while fetching the available instructors. Please try again later.'
          }
        />
      );
    }

    if (isRequestSuccessfully) {
      return (
        <>
          <h2>Request to add instructors</h2>
          <div className="info-section">
            <AlertBanner
              className="co-alert--success"
              size="lg"
              type="success"
              heading={'Your request has been successfully submitted.'}
            ></AlertBanner>
          </div>
        </>
      );
    }

    if (isSuccess && !instructors.length) {
      return (
        <NoData
          heading={
            'No instructors available for the selected location and program.'
          }
        />
      );
    }

    return (
      <>
        <h2>Request to add instructors</h2>
        {loading ? (
          <div className="info-section">
            <ProgressLine className="" label="Processing your request…" />
          </div>
        ) : (
          <>
            {discipline === DisciplineTypeDto.CHECK_AIRMAN && (
              <AlertBanner
                className="co-alert--info"
                size="sm"
                type="info"
                heading={
                  'For check airmen approval, your Air Carrier Specialist will contact you to provide you with your documentation package.'
                }
              ></AlertBanner>
            )}
            {error && (
              <AlertBanner
                className="co-alert--info"
                size="sm"
                type="error"
                heading={
                  'An error occurred while processing your request. Please try again later.'
                }
              ></AlertBanner>
            )}
            <div className="sub-header">
              <span>
                {disciplineTextMap[discipline ?? '']} available in {location}{' '}
                for {program}
              </span>
              <div className="button-section">
                <Button variant="ghost" onClick={handleSelectAll}>
                  Select All
                </Button>
                <span> ({getSelectedCount()} selected) </span>
              </div>
            </div>
            <div className="card-list">
              {instructors.map(
                instructor =>
                  instructor?.instructorId && (
                    <Card
                      key={instructor.instructorId}
                      elevation="skim"
                      orientation="vertical"
                      className={`batch-instructors-modal_card ${selected[instructor.instructorId] ? 'batch-instructors-modal__card-selected' : ''}`}
                    >
                      <div className="checkbox-container">
                        <div className="checkbox-container__item">
                          <Avatar
                            as="span"
                            elevation="base"
                            label={instructor.instructorName}
                            initials={getNameInitials(
                              instructor?.instructorName ?? ''
                            )}
                            size="sm"
                            variant="round"
                            className="avatar"
                          />
                          <span>{instructor.instructorName}</span>
                        </div>
                        <input
                          type="checkbox"
                          checked={selected[instructor.instructorId] ?? false}
                          onChange={() =>
                            handleCheckbox(instructor.instructorId ?? '')
                          }
                          aria-label={instructor.instructorName}
                        />
                      </div>
                      <div className="location-container">
                        <span>Location (s)</span>
                        <div className="location-text">
                          <div className="location-text">
                            {instructor?.availableTrainingCenters
                              ?.map(item => item.trainingCenterName)
                              .join(', ')}
                          </div>
                        </div>
                      </div>
                    </Card>
                  )
              )}
            </div>
          </>
        )}
        <div className="modal-actions">
          <span className="regulation__text">
            By clicking the “Submit” button, you acknowledge the following
            <Button
              variant="ghost"
              onClick={() => setRegulationModalOpen(true)}
            >
              regulations.
            </Button>
          </span>
          <Button
            variant="primary"
            disabled={loading || !getSelectedCount()}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button variant="secondary" disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </>
    );
  };
  return (
    <>
      <RegulationModal
        modalOpen={regulationModalOpen}
        toggleModal={() => setRegulationModalOpen(!regulationModalOpen)}
        discipline={discipline}
      />
      <Modal
        open={modalOpen}
        onCancel={handleClose}
        placement={window.innerWidth < 480 ? 'bottom' : 'right'}
        dismissible
        size="sm"
        className="batch-instructors-modal"
      >
        <div className="modal-content">{modalOpen && getModalContent()}</div>
      </Modal>
    </>
  );
};

export default BatchInstructorsModal;
