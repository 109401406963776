import Slider from 'react-slick';
import './MultipleAlertsCarosel.css';
import { Icon } from '@cae/cobalt-react';
import * as React from 'react';

interface MultipleItemsCarouselProps {
  readonly children: React.ReactNode;
}

interface CustomArrowProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const CustomRightArrow: React.FC<CustomArrowProps> = ({
  onClick,
}: CustomArrowProps) => {
  return (
    <button className="custom-right-arrow" onClick={onClick}>
      <Icon id="chevron-right-outline" size="sm" className="chevron-arrows" />
    </button>
  );
};

const CustomLeftArrow: React.FC<CustomArrowProps> = ({
  onClick,
}: CustomArrowProps) => {
  return (
    <button className="custom-left-arrow" onClick={onClick}>
      <Icon id="chevron-left-outline" size="sm" className="chevron-arrows" />
    </button>
  );
};

function MultipleItemsCarousel({
  children,
}: MultipleItemsCarouselProps): JSX.Element {
  const childrenCount = React.Children.count(children);
  const settings = {
    dots: false,
    infinite: false,
    arrows: childrenCount > 4,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    variableWidth: true,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="multiple-alerts-slider  slider-container">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}

export default MultipleItemsCarousel;
