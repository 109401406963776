/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthorityRequest } from '../models/AuthorityRequest';
import type { CaeCustomerAuthority } from '../models/CaeCustomerAuthority';
import type { OrganizationBulkResponse } from '../models/OrganizationBulkResponse';
import type { OrganizationPatchRequest } from '../models/OrganizationPatchRequest';
import type { OrganizationProfileResponse } from '../models/OrganizationProfileResponse';
import type { OrganizationProfileUpdateEmailRequest } from '../models/OrganizationProfileUpdateEmailRequest';
import type { OrganizationProfileUpdateRequest } from '../models/OrganizationProfileUpdateRequest';
import type { OrganizationRequest } from '../models/OrganizationRequest';
import type { OrganizationResponse } from '../models/OrganizationResponse';
import type { StreamingResponseBody } from '../models/StreamingResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrganizationManagementService {
  /**
   * getOrganizationByID
   * @returns OrganizationResponse OK
   * @throws ApiError
   */
  public static getOrganizationById({
    id,
  }: {
    id: string;
  }): CancelablePromise<OrganizationResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * updateOrganization
   * @returns OrganizationResponse OK
   * @throws ApiError
   */
  public static updateOrganization({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: OrganizationRequest;
  }): CancelablePromise<OrganizationResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/organizations/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * deleteOrganization
   * @returns any OK
   * @throws ApiError
   */
  public static deleteOrganization({
    id,
  }: {
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organizations/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * patchOrganization
   * @returns OrganizationResponse OK
   * @throws ApiError
   */
  public static patchOrganization({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: OrganizationPatchRequest;
  }): CancelablePromise<OrganizationResponse> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/organizations/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * getAllOrganizations
   * @returns OrganizationResponse OK
   * @throws ApiError
   */
  public static getAllOrganizations(): CancelablePromise<
    Array<OrganizationResponse>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * createOrganization
   * @returns OrganizationResponse Created
   * @throws ApiError
   */
  public static createOrganization({
    requestBody,
  }: {
    requestBody?: OrganizationRequest;
  }): CancelablePromise<OrganizationResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * uploadCSVFile_1
   * @returns OrganizationBulkResponse OK
   * @throws ApiError
   */
  public static uploadCsvFile1({
    requestBody,
  }: {
    requestBody?: {
      file: Blob;
    };
  }): CancelablePromise<Array<OrganizationBulkResponse>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/upload',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * getAuthorities
   * @returns CaeCustomerAuthority OK
   * @throws ApiError
   */
  public static getAuthorities({
    id,
  }: {
    id: string;
  }): CancelablePromise<Array<CaeCustomerAuthority>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/{id}/authorities',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * updateAuthorities
   * @returns any OK
   * @throws ApiError
   */
  public static updateAuthorities({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: AuthorityRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/organizations/{id}/authorities',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * updateOrganizationRequestEmail
   * @returns any OK
   * @throws ApiError
   */
  public static updateOrganizationRequestEmail({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: OrganizationProfileUpdateEmailRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/{id}/update-profile-request-email',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * downloadOrganizationLogo
   * @returns StreamingResponseBody OK
   * @throws ApiError
   */
  public static downloadOrganizationLogo({
    id,
  }: {
    id: string;
  }): CancelablePromise<StreamingResponseBody> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/{id}/logo',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * uploadOrganizationLogo
   * @returns any Created
   * @throws ApiError
   */
  public static uploadOrganizationLogo({
    id,
    formData,
  }: {
    id: string;
    formData?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/{id}/logo',
      path: {
        id: id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * deleteOrganizationLogo
   * @returns void
   * @throws ApiError
   */
  public static deleteOrganizationLogo({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organizations/{id}/logo',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * getOrganizationProfile
   * @returns OrganizationProfileResponse OK
   * @throws ApiError
   */
  public static getOrganizationProfile({
    id,
  }: {
    id: string;
  }): CancelablePromise<OrganizationProfileResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/{id}/profile',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * updateOrganizationProfile
   * @returns any OK
   * @throws ApiError
   */
  public static updateOrganizationProfile({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: OrganizationProfileUpdateRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/organizations/{id}/profile',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * deleteOrganizationProfileVehicleType
   * @returns void
   * @throws ApiError
   */
  public static deleteOrganizationProfileVehicleType({
    id,
    vehicleTypeId,
  }: {
    id: string;
    vehicleTypeId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organizations/{id}/profile/platform/{vehicleTypeId}',
      path: {
        id: id,
        vehicleTypeId: vehicleTypeId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
