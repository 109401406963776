/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PermissionRequest } from '../models/PermissionRequest';
import type { PermissionResponse } from '../models/PermissionResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RolePermissionsService {
  /**
   * updatePermission
   * @returns any OK
   * @throws ApiError
   */
  public static updatePermission({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: PermissionRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/permissions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * deletePermission
   * @returns any OK
   * @throws ApiError
   */
  public static deletePermission({
    id,
  }: {
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/permissions/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * findByRoleId
   * @returns PermissionResponse OK
   * @throws ApiError
   */
  public static findByRoleId({
    roleId,
  }: {
    /**
     * RoleId
     */
    roleId?: string;
  }): CancelablePromise<Array<PermissionResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/permissions',
      query: {
        roleId: roleId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * createPermission
   * @returns PermissionResponse Created
   * @throws ApiError
   */
  public static createPermission({
    requestBody,
  }: {
    requestBody?: Array<PermissionRequest>;
  }): CancelablePromise<Array<PermissionResponse>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/permissions',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
