/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceAlertsResponse } from '../models/InvoiceAlertsResponse';
import type { InvoiceDetailsResponse } from '../models/InvoiceDetailsResponse';
import type { InvoiceMetadata } from '../models/InvoiceMetadata';
import type { PagedListOfInvoiceResponse } from '../models/PagedListOfInvoiceResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InvoiceAndPaymentManagementService {
  /**
   * getInvoicePayments
   * @returns PagedListOfInvoiceResponse OK
   * @throws ApiError
   */
  public static getInvoicePayments({
    pageIndex,
    pageSize = 10,
    sort,
    location,
    platform,
    currency,
    startDueDate,
    endDueDate,
    searchText,
    issuedStartDate,
    issuedEndDate,
    status,
    paymentStatus,
    organizationId,
  }: {
    /**
     * Page index
     */
    pageIndex?: number;
    /**
     * Page size
     */
    pageSize?: number;
    /**
     * Sort attributes
     */
    sort?: Array<
      | '+type'
      | '+accountReceivableId'
      | '+transactionNumber'
      | '+legalEntityName'
      | '+dueDate'
      | '+issuedOn'
      | '-type'
      | '-accountReceivableId'
      | '-transactionNumber'
      | '-legalEntityName'
      | '-dueDate'
      | '-issuedOn'
    >;
    /**
     * Training locations
     */
    location?: Array<string>;
    /**
     * Aircraft names
     */
    platform?: Array<string>;
    /**
     * Currency
     */
    currency?: Array<string>;
    /**
     * Format - date-time (as date-time in RFC3339). All invoices start due date
     */
    startDueDate?: string;
    /**
     * Format - date-time (as date-time in RFC3339). All invoices end due date
     */
    endDueDate?: string;
    /**
     * Search term for additional filtering on any of Pilot name or Legal Entity name
     */
    searchText?: string;
    /**
     * Format - date-time (as date-time in RFC3339). Issued start date
     */
    issuedStartDate?: string;
    /**
     * Format - date-time (as date-time in RFC3339). Issued end date
     */
    issuedEndDate?: string;
    /**
     * Invoice Status
     */
    status?: Array<'Paid' | 'Unpaid' | 'NotApplicable' | 'All'>;
    /**
     * Invoice Status
     */
    paymentStatus?: Array<'Paid' | 'Unpaid' | 'NotApplicable' | 'All'>;
    organizationId?: string;
  }): CancelablePromise<PagedListOfInvoiceResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoices/paged',
      headers: {
        organizationId: organizationId,
      },
      query: {
        pageIndex: pageIndex,
        pageSize: pageSize,
        sort: sort,
        location: location,
        platform: platform,
        currency: currency,
        startDueDate: startDueDate,
        endDueDate: endDueDate,
        searchText: searchText,
        issuedStartDate: issuedStartDate,
        issuedEndDate: issuedEndDate,
        status: status,
        paymentStatus: paymentStatus,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * getInvoicePaymentMetadata
   * @returns InvoiceMetadata OK
   * @throws ApiError
   */
  public static getInvoicePaymentMetadata({
    pageIndex,
    pageSize = 10,
    sort,
    location,
    platform,
    currency,
    startDueDate,
    endDueDate,
    searchText,
    issuedStartDate,
    issuedEndDate,
    status,
    paymentStatus,
    organizationId,
  }: {
    /**
     * Page index
     */
    pageIndex?: number;
    /**
     * Page size
     */
    pageSize?: number;
    /**
     * Sort attributes
     */
    sort?: Array<
      | '+type'
      | '+accountReceivableId'
      | '+transactionNumber'
      | '+legalEntityName'
      | '+dueDate'
      | '+issuedOn'
      | '-type'
      | '-accountReceivableId'
      | '-transactionNumber'
      | '-legalEntityName'
      | '-dueDate'
      | '-issuedOn'
    >;
    /**
     * Training locations
     */
    location?: Array<string>;
    /**
     * Aircraft names
     */
    platform?: Array<string>;
    /**
     * Currency
     */
    currency?: Array<string>;
    /**
     * Format - date-time (as date-time in RFC3339). All invoices start due date
     */
    startDueDate?: string;
    /**
     * Format - date-time (as date-time in RFC3339). All invoices end due date
     */
    endDueDate?: string;
    /**
     * Search term for additional filtering on any of Pilot name or Legal Entity name
     */
    searchText?: string;
    /**
     * Format - date-time (as date-time in RFC3339). Issued start date
     */
    issuedStartDate?: string;
    /**
     * Format - date-time (as date-time in RFC3339). Issued end date
     */
    issuedEndDate?: string;
    /**
     * Invoice Status
     */
    status?: Array<'Paid' | 'Unpaid' | 'NotApplicable' | 'All'>;
    /**
     * Invoice Status
     */
    paymentStatus?: Array<'Paid' | 'Unpaid' | 'NotApplicable' | 'All'>;
    organizationId?: string;
  }): CancelablePromise<InvoiceMetadata> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoices/meta',
      headers: {
        organizationId: organizationId,
      },
      query: {
        pageIndex: pageIndex,
        pageSize: pageSize,
        sort: sort,
        location: location,
        platform: platform,
        currency: currency,
        startDueDate: startDueDate,
        endDueDate: endDueDate,
        searchText: searchText,
        issuedStartDate: issuedStartDate,
        issuedEndDate: issuedEndDate,
        status: status,
        paymentStatus: paymentStatus,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * getInvoiceDetails
   * @returns InvoiceDetailsResponse OK
   * @throws ApiError
   */
  public static getInvoiceDetails({
    accountReceivableId,
  }: {
    accountReceivableId: string;
  }): CancelablePromise<InvoiceDetailsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoices/{accountReceivableId}/details',
      path: {
        accountReceivableId: accountReceivableId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * getInvoiceAsBlob
   * @returns any OK
   * @throws ApiError
   */
  public static getInvoiceAsBlob({
    accountReceivableId,
  }: {
    accountReceivableId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoices/{accountReceivableId}/blob',
      path: {
        accountReceivableId: accountReceivableId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * getRecordAlertsCountByDueDate
   * @returns InvoiceAlertsResponse OK
   * @throws ApiError
   */
  public static getRecordAlertsCountByDueDate({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<InvoiceAlertsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoices/alerts',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
