import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { InstructorDetailsDto, InstructorManagementService } from '@/open-api';

export function useInstructorDetailsQuery(
  instructorId: string
): UseQueryResult<InstructorDetailsDto, Error> {
  return useQuery({
    queryKey: ['instructor', 'instructor-details', instructorId],
    queryFn: () => {
      return InstructorManagementService.getInstructorDetails({
        instructorId,
      });
    },
  });
}
