import { useInstructorDetailsQuery } from '@/features/instructor-management/api/useInstructorDetailsQuery';
import {
  getStatusIcon,
  statusText,
} from '@/features/instructor-management/shared/Utils.tsx';
import { Modal, Button, Icon, AlertBanner } from '@cae/cobalt-react';
import {
  ActionModal,
  ActionOverflowMenu,
} from '@/features/instructor-management';
import {
  InstructorApprovalState,
  StatusByProgramAndTrainingCenter,
} from '@/open-api';
import './InstructorDetails.css';
import { DataLoadingError, DataLoadingGrid, NoData } from '@/shared/components';
import { useState } from 'react';

interface InstructorDetailsProps {
  instructorId: string;
  instructorName: string;
  programName: string;
  trainingCenterName: string;
  groundSchoolApprovalState: string;
  simulatorApprovalState: string;
  checkingApprovalState: string;
  instructorDetailsOpen: boolean;
  toggleInstructorDetails: () => void;
  onBtnClick: (action: string) => void;
}

const InstructorDetails = ({
  instructorId,
  instructorName,
  programName,
  trainingCenterName,
  groundSchoolApprovalState,
  simulatorApprovalState,
  checkingApprovalState,
  instructorDetailsOpen,
  toggleInstructorDetails,
  onBtnClick,
}: Readonly<InstructorDetailsProps>): JSX.Element => {
  const {
    data: instructorDetails,
    isLoading,
    isSuccess,
  } = useInstructorDetailsQuery(instructorId);

  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState('');
  const [selectedLocation, setSelectedLocation] =
    useState<StatusByProgramAndTrainingCenter>({});

  const handleMenuBtnClick = (
    action: string,
    location: StatusByProgramAndTrainingCenter
  ): void => {
    setAction(action);
    setSelectedLocation(location);
    setModalOpen(true);
    toggleInstructorDetails();
  };

  const renderDisciplineSection = (
    discipline: string,
    approvalState: string
  ): JSX.Element => {
    let actionButton;
    const actionHandler = (action: string): void => {
      onBtnClick(action);
      toggleInstructorDetails();
    };

    switch (approvalState) {
      case InstructorApprovalState.APPROVED:
        actionButton = (
          <Button
            variant="ghost"
            onClick={() => actionHandler('revoke')}
            className="action-button"
            danger
          >
            <Icon id={'close-circle-outline'} /> Revoke
          </Button>
        );
        break;
      case InstructorApprovalState.UNSELECTED:
        actionButton = (
          <Button
            variant="ghost"
            onClick={() => actionHandler('approve')}
            className="action-button"
          >
            <Icon id={'checkmark-circle-outline'} /> Approve
          </Button>
        );
        break;
      case InstructorApprovalState.PENDING:
      case InstructorApprovalState.PENDING_REMOVAL:
        actionButton = <></>;
        break;
      case 'Request Cancelled':
        actionButton = <></>;
        break;
      case InstructorApprovalState.PENDING_APPROVAL_LETTER:
        actionButton = (
          <Button variant="ghost" onClick={() => {}} className="action-button">
            <Icon id={'upload-outline'} /> Upload Letter
          </Button>
        );
        break;
      case InstructorApprovalState.UNAUTHORIZED:
        actionButton = <></>;
        break;
      default:
        actionButton = <></>;
    }

    return (
      <div className="discipline-section">
        <div className="discipline-left">
          <span className="discipline-name">{discipline}</span>
          <div className="icon-text-alignment">
            <span>{getStatusIcon(approvalState)}</span>
            <span className="text">
              {statusText[approvalState as InstructorApprovalState]}
            </span>
          </div>
        </div>
        <div className="discipline-right">{actionButton}</div>
      </div>
    );
  };

  const getAvailableFor = (): JSX.Element => {
    if (isLoading) {
      return <DataLoadingGrid numberColumns={3} numberRows={3} />;
    }
    if (
      isSuccess &&
      !instructorDetails?.statusesByProgramAndTrainingCenter?.length
    ) {
      return <NoData />;
    }
    if (
      isSuccess &&
      !!instructorDetails?.statusesByProgramAndTrainingCenter?.length
    ) {
      return (
        <>
          {instructorDetails?.alerts?.map(alert => (
            <AlertBanner
              key={alert.message}
              className="co-alert--warning"
              size="sm"
              type="warning"
              heading={alert.message}
            ></AlertBanner>
          ))}
          {instructorDetails?.statusesByProgramAndTrainingCenter?.map(
            program => (
              <div key={program.program} className="program-section">
                <h4 className="program-name">{program.program}</h4>
                <h5 className="training-center-name">
                  {program.trainingCenterName}
                </h5>
                <ul className="details-list">
                  <li className="icon-text-alignment">
                    <span>
                      {getStatusIcon(program.groundSchoolApprovalState ?? '')}
                    </span>
                    <span className="text">Ground School</span>
                  </li>
                  <li className="icon-text-alignment">
                    <span>
                      {getStatusIcon(program.simulatorApprovalState ?? '')}
                    </span>
                    <span className="text">Simulator training</span>
                  </li>
                  <li className="icon-text-alignment">
                    <span>
                      {getStatusIcon(program.checkingApprovalState ?? '')}
                    </span>
                    <span className="text">Checking</span>
                  </li>
                  <li className="icon-text-alignment">
                    <ActionOverflowMenu
                      onBtnClick={handleMenuBtnClick}
                      instructor={program}
                    />
                  </li>
                </ul>
              </div>
            )
          )}
        </>
      );
    } else {
      return <DataLoadingError />;
    }
  };
  return (
    <>
      <Modal
        open={instructorDetailsOpen}
        onCancel={toggleInstructorDetails}
        placement="right"
        dismissible
        size="md"
      >
        <div className="instructor-details">
          <div className="top-half">
            <h2 className="instructor-name">{instructorName}</h2>
            <h3 className="program-name">{programName}</h3>
            <h4 className="training-center-name">{trainingCenterName}</h4>
            {renderDisciplineSection(
              'Ground School',
              groundSchoolApprovalState
            )}
            {renderDisciplineSection(
              'Simulator Training',
              simulatorApprovalState
            )}
            {renderDisciplineSection('Checking', checkingApprovalState)}
          </div>
          <div className="bottom-half">
            <h3 className="available-for-title">Available for</h3>
            {getAvailableFor()}
          </div>
        </div>
      </Modal>
      <ActionModal
        modalOpen={modalOpen}
        toggleActionModal={() => setModalOpen(false)}
        action={action}
        selectedInstructor={selectedLocation}
        program={selectedLocation?.program ?? ''}
        location={selectedLocation?.trainingCenterName ?? ''}
        trainingCenterId={selectedLocation?.trainingCenterId ?? ''}
      />
    </>
  );
};

export default InstructorDetails;
