import { Link } from 'react-router-dom';
import { Card, Carousel, Icon } from '@cae/cobalt-react';
import './DashboardCarousel.css';
import { useEffect, useState } from 'react';
import { useGetProfileQuery } from '../../../../shared/api/useGetProfileQuery';
import CustomerCare from '../../../../../public/img/dashboard/customer-care.svg';
import Documents from '../../../../../public/img/dashboard/documents.svg';
import EditProfile from '../../../../../public/img/dashboard/edit-profile.svg';
import ManageInvoicesPayments from '../../../../../public/img/dashboard/invoices-and-payments.svg';
import CompanyProfile from '../../../../../public/img/dashboard/company-info.svg';
import FleetInfo from '../../../../../public/img/dashboard/fleet-info.svg';

export function DashboardCarousel(): JSX.Element {
  const { data: profile } = useGetProfileQuery();
  const [roles, setRoles] = useState<string[]>([]);
  useEffect(() => {
    if (profile?.organizations?.[0]?.roles) {
      const roleNames = profile.organizations[0].roles
        .map(role => role.name)
        .filter((name): name is string => name !== undefined);
      setRoles(roleNames);
    }
  }, [profile]);

  const isAdmin = roles.includes('Admin');
  const isTraining = roles.includes('Training');
  const isFinance = roles.includes('Finance');

  const carousels = [
    {
      key: 'customerCare',
      display: true,
      content: (
        <Card
          className="carousel-card--small"
          style={{ backgroundColor: 'var(--blue-800)' }}
        >
          <img alt="CustomerCare" src={CustomerCare} className="image" />
          <div className="header">
            <span className="heading heading-adjustment">
              Customer care
              <br />
              available 24/7
            </span>
            <a href="https://help.cae.com/" target="_blank" rel="noreferrer">
              Get help{' '}
              <Icon className="cae-icon" id="external-link-outline" size="md" />
            </a>
          </div>
        </Card>
      ),
    },
    {
      key: 'documents',
      display: isAdmin || isTraining,
      content: (
        <Card
          className="carousel-card--small"
          style={{ backgroundColor: 'var(--yellow-800)' }}
        >
          <img alt="Documents" src={Documents} className="image documents" />
          <div className="header" style={{ color: 'var(--yellow-1)' }}>
            <span className="heading">
              Are your company
              <br />
              documents up to date?
            </span>
            <Link to="/documents/overview" style={{ color: 'var(--yellow-1)' }}>
              Learn more <Icon id="arrow-right-solid" size="md" />
            </Link>
          </div>
        </Card>
      ),
    },
    {
      key: 'editProfile',
      display: true,
      content: (
        <Card
          className="carousel-card--small"
          style={{ backgroundColor: 'var(--blue-800)' }}
        >
          <img alt="EditProfile" src={EditProfile} className="image profile" />
          <div className="header">
            <span className="heading heading-adjustment">
              Edit your profile
              <br />
              information
            </span>
            <Link to="/my-profile/info">
              Learn more <Icon id="arrow-right-solid" size="md" />
            </Link>
          </div>
        </Card>
      ),
    },
    {
      key: 'manageInvoicesPayments',
      display: isAdmin || isFinance,
      content: (
        <Card
          className="carousel-card--small"
          style={{ backgroundColor: 'var(--blue-800)' }}
        >
          <img
            alt="ManageInvoicesPayments"
            src={ManageInvoicesPayments}
            className="image"
          />
          <div className="header">
            <span className="heading">
              Manage your invoices
              <br />
              and payments
            </span>
            <Link to="/finance">
              Learn more <Icon id="arrow-right-solid" size="md" />
            </Link>
          </div>
        </Card>
      ),
    },
    {
      key: 'companyProfile',
      display: isAdmin,
      content: (
        <Card
          className="carousel-card--small"
          style={{ backgroundColor: 'var(--yellow-800)' }}
        >
          <img
            alt="CompanyProfile"
            src={CompanyProfile}
            className="image profile"
          />
          <div className="header" style={{ color: 'var(--yellow-1)' }}>
            <span className="heading company-profile">
              Finish your
              <br />
              company&apos;s profile
            </span>
            <Link
              to="/company-profile/company-info"
              style={{ color: 'var(--yellow-1)' }}
            >
              Learn more <Icon id="arrow-right-solid" size="md" />
            </Link>
          </div>
        </Card>
      ),
    },
    {
      key: 'fleetInfo',
      display: isAdmin,
      content: (
        <Card
          className="carousel-card--small"
          style={{ backgroundColor: 'var(--yellow-800)' }}
        >
          <img alt="FleetInfo" src={FleetInfo} className="image flight" />
          <div className="header" style={{ color: 'var(--yellow-1)' }}>
            <span className="heading">
              Add your fleet to your
              <br />
              organization profile
            </span>
            <Link
              to="/company-profile/fleet-info"
              style={{ color: 'var(--yellow-1)' }}
            >
              Learn more <Icon id="arrow-right-solid" size="md" />
            </Link>
          </div>
        </Card>
      ),
    },
  ];
  const visibleCarousels = carousels.filter(carousel => carousel.display);

  return (
    <Carousel
      arrowSize="sm"
      arrowStyle={{ color: 'var(--neutral-400)' }}
      className="dashboard-carousel"
      cycle={0}
      height="9.5rem"
      indicatorsStyle={{
        color: 'hsla(0,0%,0%,0.15)',
        margin: '0 0 0.5rem auto',
        right: '1rem',
      }}
      orientation="horizontal"
      width="24rem"
      withIndicators
    >
      {visibleCarousels.map(carousel => (
        <Carousel.Slide key={carousel.key}>{carousel.content}</Carousel.Slide>
      ))}
    </Carousel>
  );
}

DashboardCarousel.displayName = 'DashboardCarousel';
